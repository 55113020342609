import { useEffect, useId, useMemo, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
    Button,
    Checkbox,
    Gutter,
    InputField,
    LoadingIndicator,
    RawHtml,
    Selector,
    Text,
    TextAreaField,
    Sidepanel,
    useTracking,
    useKompanGetQuotePanelState,
    FormReceipt,
    FormError,
} from '~/shared/components';
import { StyledFormElement } from '~/shared/components/FormElements/components/styled';
import { useTranslation } from '~/shared/utils/translation';
import { ModuleContainer } from '../ModuleContainer';
import {
    StyledContent,
    StyledForm,
    StyledGrid,
    StyledM40Container,
    StyledModalWrapper,
} from './styled';
import { FormConversionSource, M40ContactFormModule } from '~/lib/data-contract';
import { postForm, FormNameType } from '$shared/utils/forms';
import { Headline } from '../Headline/Headline';
import { getCurrentMarket } from '~/templates/pages/utils';
import { usePage } from '$templates/pages';
import { SelectorOption } from '~/shared/components/FormElements/components/Selector';
import { useLazyReCaptcha } from '$shared/utils/lazy-recaptcha';
import {
    countryCodeKey,
    getCountryCode,
    getCountryList,
    getCountryName,
} from '$lib/twoLetterIsoCodeCountryList';
import { useCookieConsent } from '~/shared/hooks/useCookieConsent';
import { useMarket } from '~/shared/utils';
import { useTrackFormBegin } from '../../hooks/useTrackFormBegin';
import {
    acceptedFiles,
    defaultFileSizeLimits,
    validateFiles,
} from '~/shared/components/FormElements/components/InputField/utils';
import { postFile } from '~/shared/utils/forms/post-form';
import {
    emailRegex,
    internationalPhoneNumberRegex,
    phoneNumberRegex,
    validateEmail,
} from '~/shared/components/FormElements/utils';

export type M40ContactFormProps = M40ContactFormModule & {
    initialData?: Partial<ContactFormInputs>;
    textAreaMinHeight?: number;
    showFileInput?: boolean;
};

export type ContactFormInputs = {
    CompanyName: string;
    LastName: string;
    Telephone1: string;
    EmailAddress1: string;
    Address1_PostalCode: string;
    Address1_Country: string;
    Address1_Line1: string;
    SubscribeNewsletter: boolean;
    Topic: string;
    SourceCampaignCode: string;
    ConversionURL: string;
    Token: string;
    Description: string;
    NavCompanyCode: string;
    ConversionSource: FormConversionSource;
    FileInput: FileList | null;
    [key: `Attachment${number}`]: string;
};

const countryList = getCountryList();

export const M40ContactForm = ({
    headline,
    description,
    receiptHeadline,
    receiptCallToAction,
    receiptDescription,
    sourceCampaignCode,
    disclaimerShortText,
    legalDisclaimer,
    topic,
    leadDescription,
    pageElementIndex,
    disableModuleAnimation,
    loadOnSidepanel,
    skipReceipt = false,
    onFormSubmit,
    onFormError,
    onPanelClose,
    disableTracking = false,
    FormNameTypeEndpoint = FormNameType.CONTACT_FORM,
    conversionSource = FormConversionSource.M40ContactForm,
    injectMessage,
    trackingEvent,
    subscribeNewsletterRequired = false,
    descriptionMessage,
    initialData = {},
    textAreaMinHeight,
    showFileInput,
    ...rest
}: M40ContactFormProps) => {
    const { market } = usePage();
    const { translate } = useTranslation();
    const { trackFormLead } = useTracking();
    const { handleTrackFormBegin, handleTrackFormBeginOnBlur } = useTrackFormBegin();
    const currentMarket = getCurrentMarket();
    const { addMarketingToPayload } = useCookieConsent();
    const errorFlag = useRef(false);
    const [showReceiptModal, setShowReceiptModal] = useState(false);
    const [showDisclaimerModal, setShowDisclaimerModal] = useState(false);
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        trigger,
        reset,
        formState: { errors, isSubmitting },
    } = useForm<ContactFormInputs>({
        defaultValues: {
            ...initialData,
            SubscribeNewsletter: false,
        },
    });
    const { visible, hide } = useKompanGetQuotePanelState();
    const [asFiles, setAsFiles] = useState(false);

    const { executeRecaptcha } = useLazyReCaptcha();
    const { culture } = useMarket();

    const userEmailRequest = useRef('');

    useEffect(() => {
        if (market && market !== 'INT') {
            setValue('Address1_Country', market);
        }
        return () => {
            // Clear value after unmount
            reset();
        };
    }, [market, setValue]);

    useEffect(() => {
        const fileList = watch('FileInput');
        setAsFiles(fileList && fileList?.length > 0 ? true : false);
    }, [watch('FileInput')]);

    useEffect(() => {
        if (descriptionMessage) {
            setValue('Description', descriptionMessage);
        }
        return () => {
            reset();
        };
    }, [descriptionMessage, setValue]);

    const setCountry = (option?: SelectorOption | null) => {
        if (option?.value) {
            setValue('Address1_Country', option.value);
            // useForm needs a little help revalidating this field type.
            setTimeout(() => {
                trigger('Address1_Country');
            });
        }
    };

    const onSubmit: SubmitHandler<ContactFormInputs> = (data) => handleFormSubmit(data);

    const handleFormSubmit = async (data: ContactFormInputs) => {
        if (isSubmitting) return;
        errorFlag.current = false;
        try {
            if (typeof executeRecaptcha === 'function') {
                const token = await executeRecaptcha('submit');
                data.Token = token;
            } else {
                throw new Error('executeRecaptcha is not defined');
            }

            const FileList = data.FileInput;
            let fileReferences: string[] = [];

            if (FileList && FileList.length > 0) {
                const formData = new FormData();

                for (let i = 0; i < FileList.length; i++) {
                    formData.append('fileInput', FileList[i]);
                }

                try {
                    const result = await postFile(formData);
                    fileReferences = result;
                } catch (err) {
                    console.error(err);
                    errorFlag.current = true;
                    onFormError && onFormError();
                }
            }

            const payloadWithMarketing = addMarketingToPayload(data);

            payloadWithMarketing.Description = `Customer message: ${
                payloadWithMarketing.Description ?? ''
            }`;

            if (injectMessage) {
                payloadWithMarketing.Description = `${payloadWithMarketing.Description} \n\n${injectMessage}`;
            }

            if (leadDescription) {
                payloadWithMarketing.Description = `${leadDescription} \n\n${payloadWithMarketing.Description}`;
            }

            if (fileReferences && fileReferences.length > 0) {
                payloadWithMarketing.FileInput = null;
                fileReferences.forEach((fileUrl, idx) => {
                    payloadWithMarketing[`Attachment${idx + 1}`] = fileUrl;
                });
            }

            payloadWithMarketing.ConversionSource = conversionSource;
            payloadWithMarketing.ConversionURL = window.location.href;
            payloadWithMarketing.SourceCampaignCode = sourceCampaignCode ?? 'Website';
            payloadWithMarketing.Topic = topic ?? '';

            if (market && market === 'INT')
                payloadWithMarketing.Address1_Country =
                    getCountryCode(payloadWithMarketing.Address1_Country)?.[0] ||
                    payloadWithMarketing.Address1_Country;

            if (!disableTracking) {
                trackFormLead({
                    form_name: headline || 'generic',
                    country:
                        market && market !== 'INT'
                            ? getCountryName(market as countryCodeKey)
                            : data.Address1_Country,
                });
            }

            if (trackingEvent) trackingEvent(data);

            await postForm(FormNameTypeEndpoint, payloadWithMarketing);
            userEmailRequest.current = data.EmailAddress1;
            reset();
            descriptionMessage && setValue('Description', descriptionMessage);
            onFormSubmit && onFormSubmit(data);
        } catch (err) {
            console.error(err);
            errorFlag.current = true;
            onFormError && onFormError();
        } finally {
            if (errorFlag.current == true || skipReceipt == false) {
                setShowReceiptModal(true);
            }
        }
    };

    const subscribeNewsletterId = useId();

    const isInternationalMarket = useMemo(() => market && market === 'INT', [market]);

    return (
        <ModuleContainer
            pageElementIndex={pageElementIndex}
            disableModuleAnimation={disableModuleAnimation}
            {...rest}
        >
            <StyledM40Container loadOnSidepanel={loadOnSidepanel}>
                <Gutter>
                    <StyledGrid loadOnSidepanel={loadOnSidepanel}>
                        <StyledContent>
                            {headline && <Headline variant="display3">{headline}</Headline>}
                            {description && <Text variant="body">{description}</Text>}
                        </StyledContent>
                        <StyledForm>
                            <form
                                onFocus={handleTrackFormBegin}
                                onBlur={handleTrackFormBeginOnBlur}
                                onSubmit={handleSubmit(onSubmit)}
                            >
                                <StyledFormElement>
                                    <InputField
                                        id={'CompanyName'}
                                        label={translate(
                                            'Kompan.ContactForm.InputLabelOrganization',
                                        )}
                                        value={watch('CompanyName') || ''}
                                        autoComplete={'off'}
                                        {...register('CompanyName')}
                                        maxLength={255}
                                    />
                                </StyledFormElement>
                                <StyledFormElement>
                                    <InputField
                                        id={'LastName'}
                                        label={translate('Kompan.ContactForm.InputLabelFullName')}
                                        value={watch('LastName') || ''}
                                        invalidMessage={
                                            errors.LastName
                                                ? translate('Kompan.ContactForm.InputErrorFullName')
                                                : ''
                                        }
                                        isInvalid={Boolean(errors.LastName)}
                                        autoComplete={'off'}
                                        {...register('LastName', { required: true })}
                                        maxLength={255}
                                    />
                                </StyledFormElement>
                                <StyledFormElement>
                                    <InputField
                                        id={'Telephone1'}
                                        label={translate('Kompan.ContactForm.InputLabelPhone')}
                                        value={watch('Telephone1') || ''}
                                        autoComplete={'off'}
                                        invalidMessage={
                                            errors.Telephone1
                                                ? isInternationalMarket
                                                    ? translate(
                                                          'Kompan.ContactForm.InputInternationalErrorPhone',
                                                      )
                                                    : translate(
                                                          'Kompan.ContactForm.InputErrorPhone',
                                                      )
                                                : ''
                                        }
                                        isInvalid={Boolean(errors.Telephone1)}
                                        {...register('Telephone1', {
                                            required: true,
                                            pattern: isInternationalMarket
                                                ? internationalPhoneNumberRegex
                                                : phoneNumberRegex,
                                        })}
                                        maxLength={255}
                                    />
                                </StyledFormElement>
                                <StyledFormElement>
                                    <InputField
                                        id={'EmailAddress1'}
                                        label={translate('Kompan.ContactForm.InputLabelEmail')}
                                        value={watch('EmailAddress1') || ''}
                                        invalidMessage={
                                            errors.EmailAddress1
                                                ? translate('Kompan.ContactForm.InputErrorEmail')
                                                : ''
                                        }
                                        isInvalid={!validateEmail(watch('EmailAddress1'))}
                                        autoComplete={'off'}
                                        {...register('EmailAddress1', {
                                            required: true,
                                            pattern: emailRegex,
                                        })}
                                        maxLength={255}
                                    />
                                </StyledFormElement>
                                <StyledFormElement>
                                    <InputField
                                        id={'Address1_Line1'}
                                        label={translate('Kompan.ContactForm.InputLabelAddress')}
                                        value={watch('Address1_Line1') || ''}
                                        autoComplete={'off'}
                                        {...register('Address1_Line1')}
                                        maxLength={255}
                                    />
                                </StyledFormElement>
                                {market && market === 'INT' && (
                                    <StyledFormElement>
                                        <Selector
                                            id={'Address1_Country'}
                                            label={translate(
                                                'Kompan.ContactForm.InputLabelCountry',
                                            )}
                                            value={watch('Address1_Country') || ''}
                                            autoComplete={'off'}
                                            isInvalid={Boolean(errors.Address1_Country)}
                                            invalidMessage={
                                                errors.Address1_Country
                                                    ? translate(
                                                          'Kompan.ContactForm.InputErrorCountry',
                                                      )
                                                    : ''
                                            }
                                            {...register('Address1_Country', { required: true })}
                                            maxLength={255}
                                            options={countryList}
                                            onChangeHandler={setCountry}
                                            maxHeight={300}
                                        />
                                    </StyledFormElement>
                                )}
                                <StyledFormElement>
                                    <InputField
                                        id={'Address1_PostalCode'}
                                        label={translate('Kompan.ContactForm.InputLabelPostalCode')}
                                        value={watch('Address1_PostalCode') || ''}
                                        autoComplete={'off'}
                                        isInvalid={Boolean(errors.Address1_PostalCode)}
                                        invalidMessage={
                                            errors.Address1_PostalCode
                                                ? translate(
                                                      'Kompan.ContactForm.InputErrorPostalCode',
                                                  )
                                                : ''
                                        }
                                        {...register('Address1_PostalCode', { required: true })}
                                        maxLength={255}
                                    />
                                </StyledFormElement>
                                <StyledFormElement>
                                    <TextAreaField
                                        id={'Description'}
                                        label={translate('Kompan.ContactForm.InputLabelTopic')}
                                        value={watch('Description') || ''}
                                        autoComplete={'off'}
                                        isInvalid={Boolean(errors.Description)}
                                        invalidMessage={
                                            errors.Description
                                                ? translate('Kompan.ContactForm.InputErrorMessage')
                                                : ''
                                        }
                                        {...register('Description', { required: true })}
                                        maxLength={1500}
                                        textAreaMinHeight={textAreaMinHeight}
                                    />
                                </StyledFormElement>
                                {showFileInput && (
                                    <StyledFormElement>
                                        <InputField
                                            id={'FileInput'}
                                            label={translate(
                                                'Kompan.ContactForm.InputLabelFileInput',
                                                '',
                                            )}
                                            type="file"
                                            autoComplete={'off'}
                                            multiple
                                            asFiles={asFiles}
                                            isInvalid={
                                                !validateFiles({
                                                    files: watch('FileInput'),
                                                    fileSizeLimits: defaultFileSizeLimits,
                                                    translate,
                                                })
                                            }
                                            invalidMessage={errors.FileInput?.message}
                                            {...register('FileInput', {
                                                required: false,
                                                validate: (files) =>
                                                    validateFiles({
                                                        files,
                                                        fileSizeLimits: defaultFileSizeLimits,
                                                        translate,
                                                    }),
                                            })}
                                            accept={acceptedFiles}
                                        />
                                    </StyledFormElement>
                                )}
                                <StyledFormElement
                                    style={{ display: 'flex', alignItems: 'center' }}
                                >
                                    <Checkbox
                                        type="checkbox"
                                        label={
                                            disclaimerShortText ||
                                            translate('Kompan.ContactForm.InputLabelNewsletter')
                                        }
                                        readMore={
                                            legalDisclaimer && (
                                                <Button
                                                    variant={'Plain'}
                                                    size={'Inline'}
                                                    type={'button'}
                                                    onClick={() => setShowDisclaimerModal(true)}
                                                >
                                                    {translate('Kompan.Generic.ReadMore')}
                                                </Button>
                                            )
                                        }
                                        isInvalid={Boolean(errors.SubscribeNewsletter)}
                                        checked={watch('SubscribeNewsletter')}
                                        id={subscribeNewsletterId}
                                        {...register('SubscribeNewsletter', {
                                            required:
                                                !!receiptCallToAction ||
                                                subscribeNewsletterRequired,
                                        })}
                                    />
                                </StyledFormElement>
                                {currentMarket && (
                                    <input
                                        type="hidden"
                                        {...register('NavCompanyCode')}
                                        value={currentMarket.navCompanyCode}
                                    />
                                )}
                                <StyledFormElement>
                                    <Button icon="arrow right" disabled={isSubmitting}>
                                        {translate('Kompan.ContactForm.ButtonTextSubmit')}
                                        {isSubmitting && (
                                            <LoadingIndicator width={20} height={20} />
                                        )}
                                    </Button>
                                </StyledFormElement>
                            </form>
                        </StyledForm>
                    </StyledGrid>
                </Gutter>
            </StyledM40Container>

            <Sidepanel
                open={showReceiptModal}
                culture={culture}
                onClose={() => {
                    setShowReceiptModal(false);
                    errorFlag.current = false;
                    onPanelClose && onPanelClose();
                    if (visible) hide();
                }}
            >
                <StyledModalWrapper>
                    {!errorFlag.current && (
                        <FormReceipt
                            headline={receiptHeadline}
                            description={receiptDescription?.replace(
                                '[user_email]',
                                `${userEmailRequest.current}`,
                            )}
                            callToAction={receiptCallToAction}
                        />
                    )}
                    {errorFlag.current && (
                        <FormError
                            headline={translate('Kompan.ContactForm.ErrorHeader')}
                            description={translate('Kompan.ContactForm.ErrorText')}
                        />
                    )}
                </StyledModalWrapper>
            </Sidepanel>

            <Sidepanel
                open={showDisclaimerModal}
                culture={culture}
                onClose={() => setShowDisclaimerModal(false)}
                withSSR
            >
                <RawHtml html={legalDisclaimer} />
            </Sidepanel>
        </ModuleContainer>
    );
};
